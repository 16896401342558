import { useStaticQuery, graphql } from "gatsby";
import React from "react";

import { CircleLink } from "src/components/CircleLink/CircleLink";
import { SectionHeading } from "src/components/SectionHeading/SectionHeading";

import "./GraphItemLines.scss";
import "./TitleGraph.scss";

export const TitleGraph = () => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "homepage" }) {
          pageBuilder {
            sections {
              elements {
                ...Element04
              }
            }
          }
        }
      }
    `
  );

  const element = data.wpPage.pageBuilder.sections[0].elements[3];

  const ListItem = ({ value }) => (
    <div className="title-graph__list-item graph-item">
      <h4>{value.title}</h4>
      <h5 className="font-space-mono">{value.description}</h5>
    </div>
  );

  return (
    <section className="title-graph container-grid">
      <SectionHeading title={element.title} subtitle={element.subtitle} className="title-graph__heading" />
      <div className="title-graph__list">
        {element.titleList.map((fields, index) => (
          <ListItem key={"title-graph-" + index} value={fields} />
        ))}
      </div>
      <div className="title-graph__repeats">
        <div className="arrow-bg">
          <span className="text">{element.repeatsTitle}</span>
        </div>
      </div>
      <div className="title-graph__hand-link-wrap">
        <CircleLink to="/contact" className="title-graph__hand-link" linkTitle={element.linkTitle}></CircleLink>
      </div>
      <div className="title-graph__description font-space-mono">{element.description}</div>
    </section>
  );
};

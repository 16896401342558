import { useStaticQuery, graphql } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";
import throttle from "lodash/throttle";
import React, { useRef, useLayoutEffect, useState } from "react";

import ScrollDownIcon from "src/assets/images/ICON-scroll-down.inline.svg";
import { Button } from "src/components/Button/Button";

import "./Hero.scss";

const frameCount = 100;
let count = frameCount / 3;
let opacityStep = 1 / count;
const heroHiddenState = { opacityEnding: 0, fadeOutAtStart: 0, opacity: 0, zIndex: -10 };

const setFadeKeyframes = () => {
  let fadeKeyframeArray = Array();
  let fadeOpacity = 1;
  for (let i = 0; i <= frameCount; i++) {
    if (i < frameCount / 5) {
      fadeKeyframeArray.push(fadeOpacity);
      fadeOpacity -= opacityStep;
    } else {
      fadeKeyframeArray.push(0);
    }
  }
  return fadeKeyframeArray;
};

const setKeyframes = (start) => {
  if (start.endFrame) {
    count = frameCount / 3 / start.endFrame;
  }
  let keyframeArray = Array();
  let scaleStep = (start.scale - 1) / count;
  let xStep = start.x / count;
  let yStep = start.y / count;
  let opacity = 0;
  let newKeyFrame = {};
  let ending = {
    scale: 1,
    x: 0,
    y: 0,
    opacity: 1,
  };

  // loop that sets keyframes, third keyframes for entrance, third for static position, and third for ending animation
  for (let i = 0; i <= frameCount; i++) {
    if (i < frameCount / 3) {
      newKeyFrame = {
        scale: start.scale <= 1 ? 1 : start.scale,
        x: xStep > 0 ? (start.x < xStep ? 0 : start.x) : start.x > xStep ? 0 : start.x,
        y: yStep > 0 ? (start.y < yStep ? 0 : start.y) : start.y > yStep ? 0 : start.y,
        opacity: opacity >= 1 ? 1 : opacity,
        opacityEnding: 1,
      };
      keyframeArray.push(newKeyFrame);

      start.scale -= scaleStep;
      xStep < 0 ? (start.x -= xStep) : (start.x -= Math.abs(xStep));
      yStep < 0 ? (start.y -= yStep) : (start.y -= Math.abs(yStep));
      opacity += opacityStep;
    } else if (i < (frameCount / 3) * 2) {
      newKeyFrame = {
        scale: 1,
        x: 0,
        y: 0,
        opacity: 1,
        opacityEnding: 1,
      };
      keyframeArray.push(newKeyFrame);
    } else {
      newKeyFrame = {
        scale: 1,
        x: -ending.y,
        y: ending.y,
        opacity: ending.opacity,
        opacityEnding: ending.opacity,
      };
      keyframeArray.push(newKeyFrame);
      ending.scale -= scaleStep;
      ending.y -= Math.abs(yStep);
      ending.opacity -= opacityStep;
    }
  }
  return keyframeArray;
};

export const Hero = () => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "homepage" }) {
          pageBuilder {
            sections {
              elements {
                ...Element01
              }
            }
          }
        }
        allWp {
          nodes {
            generalSettings {
              title
            }
          }
        }
        placeholderImage: file(relativePath: { eq: "hero-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const page = data.wpPage.pageBuilder.sections[0].elements[0];
  const genSettings = data.allWp.nodes[0].generalSettings;

  const [matrixState, setMatrixState] = useState({
    opacity: 0,
    opacityEnding: 1,
    fadeOutAtStart: 1,
    firstLineScale: 0,
    firstLineX: 0,
    firstLineY: 0,
    secondLineScale: 0,
    secondLineX: 0,
    thirdLineX: 0,
    thirdLineScale: 0,
    secondLineY: 0,
    thirdLineY: 0,
    bottomTextScale: 0,
    bottomTextX: 0,
    bottomTextY: 0,
    icon1Scale: 0,
    icon1X: 0,
    icon1Y: 0,
    icon2Scale: 0,
    icon2X: 0,
    icon2Y: 0,
    icon3Scale: 0,
    icon3X: 0,
    icon3Y: 0,
    icon4Scale: 0,
    icon4X: 0,
    icon4Y: 0,
    icon5Scale: 0,
    icon5X: 0,
    icon5Y: 0,
    icon6Scale: 0,
    icon6X: 0,
    icon6Y: 0,
  });

  const breakpoints = useBreakpoint();
  const hero = useRef();
  const heroWrapper = useRef();

  useLayoutEffect(() => {
    // set up keyframes for all elements
    const firstLineKeyframes = setKeyframes({ scale: 5, x: 0, y: -450, endFrame: 1 });
    const secondLineKeyframes = setKeyframes({ scale: 5, x: -5000, y: 500, endFrame: 1 });
    const thirdLineKeyframes = setKeyframes({ scale: 5, x: 5000, y: 1000, endFrame: 1 });
    const bottomTextKeyframes = setKeyframes({ scale: 5, x: 0, y: 2000, endFrame: 1 });
    const icon1Keyframes = setKeyframes({ scale: 3, x: -1000, y: 2000, endFrame: 1.8 });
    const icon2Keyframes = setKeyframes({ scale: 3, x: -500, y: 2000, endFrame: 1.7 });
    const icon3Keyframes = setKeyframes({ scale: 3, x: -150, y: 2000, endFrame: 1.6 });
    const icon4Keyframes = setKeyframes({ scale: 3, x: 150, y: 2000, endFrame: 1.5 });
    const icon5Keyframes = setKeyframes({ scale: 3, x: 500, y: 2000, endFrame: 1.3 });
    const icon6Keyframes = setKeyframes({ scale: 3, x: 1000, y: 2000, endFrame: 1.1 });
    const fadeKeyframes = setFadeKeyframes();
    // set up fixed variables
    const heroWrapperHeight = heroWrapper.current.scrollHeight;
    const screenHeight = document.documentElement.clientHeight;
    const heroHeight = heroWrapperHeight - screenHeight / 2;

    // hide Hero if page refreshed and already scrolled
    if (document.documentElement.scrollTop > heroHeight + 10) {
      setMatrixState(heroHiddenState);
    }
    // function for changing keyframes
    const goToKeyframe = () => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop <= heroHeight + 10) {
        const scrollFraction = scrollTop / (heroHeight + 100);
        const frameIndex = Math.min(frameCount - 1, Math.ceil(scrollFraction * frameCount));
        requestAnimationFrame(() => {
          setMatrixState({
            opacity: firstLineKeyframes[frameIndex].opacity,
            opacityEnding: firstLineKeyframes[frameIndex].opacityEnding,
            fadeOutAtStart: fadeKeyframes[frameIndex],
            firstLineScale: firstLineKeyframes[frameIndex].scale,
            firstLineX: firstLineKeyframes[frameIndex].x,
            firstLineY: firstLineKeyframes[frameIndex].y,
            secondLineScale: secondLineKeyframes[frameIndex].scale,
            secondLineX: secondLineKeyframes[frameIndex].x,
            secondLineY: secondLineKeyframes[frameIndex].y,
            thirdLineScale: thirdLineKeyframes[frameIndex].scale,
            thirdLineX: thirdLineKeyframes[frameIndex].x,
            thirdLineY: thirdLineKeyframes[frameIndex].y,
            bottomTextScale: bottomTextKeyframes[frameIndex].scale,
            bottomTextX: bottomTextKeyframes[frameIndex].x,
            bottomTextY: bottomTextKeyframes[frameIndex].y,
            icon1Scale: icon1Keyframes[frameIndex].scale,
            icon1X: icon1Keyframes[frameIndex].x,
            icon1Y: icon1Keyframes[frameIndex].y,
            icon2Scale: icon2Keyframes[frameIndex].scale,
            icon2X: icon2Keyframes[frameIndex].x,
            icon2Y: icon2Keyframes[frameIndex].y,
            icon3Scale: icon3Keyframes[frameIndex].scale,
            icon3X: icon3Keyframes[frameIndex].x,
            icon3Y: icon3Keyframes[frameIndex].y,
            icon4Scale: icon4Keyframes[frameIndex].scale,
            icon4X: icon4Keyframes[frameIndex].x,
            icon4Y: icon4Keyframes[frameIndex].y,
            icon5Scale: icon5Keyframes[frameIndex].scale,
            icon5X: icon5Keyframes[frameIndex].x,
            icon5Y: icon5Keyframes[frameIndex].y,
            icon6Scale: icon6Keyframes[frameIndex].scale,
            icon6X: icon6Keyframes[frameIndex].x,
            icon6Y: icon6Keyframes[frameIndex].y,
          });
        });
      } else {
        if (matrixState.opacityEnding != 0) {
          setMatrixState(heroHiddenState);
        }
      }
    };

    const throttledKeyframes = throttle(goToKeyframe, 50);

    // scroll event listener if screen width
    if (breakpoints.tabletLandscape) {
      window.addEventListener("scroll", throttledKeyframes);
      return () => window.removeEventListener("scroll", throttledKeyframes);
    }
  }, [breakpoints, matrixState.opacityEnding]);

  if (breakpoints.tabletLandscape) {
    return (
      <section
        className="hero c-white bg-c-primary"
        ref={heroWrapper}
        style={{
          zIndex: matrixState.zIndex,
        }}
      >
        <div
          className="hero__scroll-down"
          style={{
            opacity: matrixState.fadeOutAtStart,
          }}
        >
          <span className="text">Your On-Demand Marketing Team.</span>
          <div className="hero__scroll-down-blink">
            <ScrollDownIcon />
          </div>
        </div>
        <div
          className="hero__wrapper"
          ref={hero}
          style={{
            opacity: matrixState.opacityEnding,
          }}
        >
          <div className="hero__icons">
            <div
              className="icon1"
              style={{
                transform: `matrix(${matrixState.icon1Scale}, 0, 0, ${matrixState.icon1Scale}, ${matrixState.icon1X}, ${matrixState.icon1Y})`,
                opacity: matrixState.opacity,
              }}
            >
              <StaticImage src="../../assets/images/hero-icon1.jpg" alt="Isovera One Icons" placeholder="blurred" width={228} quality={100} />
            </div>
            <div
              className="icon2"
              style={{
                transform: `matrix(${matrixState.icon2Scale}, 0, 0, ${matrixState.icon2Scale}, ${matrixState.icon2X}, ${matrixState.icon2Y})`,
                opacity: matrixState.opacity,
              }}
            >
              <StaticImage src="../../assets/images/hero-icon2.jpg" alt="Isovera One Icons" placeholder="blurred" width={136} quality={100} />
            </div>
            <div
              className="icon3"
              style={{
                transform: `matrix(${matrixState.icon3Scale}, 0, 0, ${matrixState.icon3Scale}, ${matrixState.icon3X}, ${matrixState.icon3Y})`,
                opacity: matrixState.opacity,
              }}
            >
              <StaticImage src="../../assets/images/hero-icon3.jpg" alt="Isovera One Icons" placeholder="blurred" width={233} quality={100} />
            </div>
            <div
              className="icon4"
              style={{
                transform: `matrix(${matrixState.icon4Scale}, 0, 0, ${matrixState.icon4Scale}, ${matrixState.icon4X}, ${matrixState.icon4Y})`,
                opacity: matrixState.opacity,
              }}
            >
              <StaticImage src="../../assets/images/hero-icon4.jpg" alt="Isovera One Icons" placeholder="blurred" width={186} quality={100} />
            </div>
            <div
              className="icon5"
              style={{
                transform: `matrix(${matrixState.icon5Scale}, 0, 0, ${matrixState.icon5Scale}, ${matrixState.icon5X}, ${matrixState.icon5Y})`,
                opacity: matrixState.opacity,
              }}
            >
              <StaticImage src="../../assets/images/hero-icon5.jpg" alt="Isovera One Icons" placeholder="blurred" width={207} quality={100} />
            </div>
            <div
              className="icon6"
              style={{
                transform: `matrix(${matrixState.icon6Scale}, 0, 0, ${matrixState.icon6Scale}, ${matrixState.icon6X}, ${matrixState.icon6Y})`,
                opacity: matrixState.opacity,
              }}
            >
              <StaticImage src="../../assets/images/hero-icon6.jpg" alt="Isovera One Icons" placeholder="blurred" width={178} quality={100} />
            </div>
          </div>
          <h1 className="hero__main-title">{genSettings.title}</h1>
          <h2 className="hero__subtitle">
            <span
              className="line first"
              style={{
                transform: `matrix(${matrixState.firstLineScale}, 0, 0, ${matrixState.firstLineScale}, ${matrixState.firstLineX}, ${matrixState.firstLineY})`,
                opacity: matrixState.opacity,
              }}
            >
              {page.mainTitle.firstLine}
            </span>
            <span
              className="line second"
              style={{
                transform: `matrix(${matrixState.secondLineScale}, 0, 0, ${matrixState.secondLineScale}, ${matrixState.secondLineX}, ${matrixState.secondLineY})`,
                opacity: matrixState.opacity,
              }}
            >
              {page.mainTitle.secondLine}
            </span>
            <span
              className="line third"
              style={{
                transform: `matrix(${matrixState.thirdLineScale}, 0, 0, ${matrixState.thirdLineScale}, ${matrixState.thirdLineX}, ${matrixState.thirdLineY})`,
                opacity: matrixState.opacity,
              }}
            >
              {page.mainTitle.thirdLine}
            </span>
          </h2>
          <div
            className="bottom-text"
            style={{
              transform: `matrix(${matrixState.bottomTextScale}, 0, 0, ${matrixState.bottomTextScale}, ${matrixState.bottomTextX}, ${matrixState.bottomTextY})`,
              opacity: matrixState.opacity,
            }}
          >
            <p className="hero__description font-space-mono">{page.description}</p>
            <Button link="/contact" className="btn btn--primary hero__button" href>
              {page.buttonText}
            </Button>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="hero hero__mobile c-white bg-c-primary" ref={heroWrapper}>
        <div className="hero__wrapper" ref={hero}>
          <div className="hero__icons">
            <div className="icon1">
              <StaticImage src="../../assets/images/hero-icon1.jpg" alt="Isovera One Icons" placeholder="blurred" width={228} quality={100} />
            </div>
            <div className="icon2">
              <StaticImage src="../../assets/images/hero-icon2.jpg" alt="Isovera One Icons" placeholder="blurred" width={136} quality={100} />
            </div>
            <div className="icon3">
              <StaticImage src="../../assets/images/hero-icon3.jpg" alt="Isovera One Icons" placeholder="blurred" width={233} quality={100} />
            </div>
            <div className="icon4">
              <StaticImage src="../../assets/images/hero-icon4.jpg" alt="Isovera One Icons" placeholder="blurred" width={186} quality={100} />
            </div>
            <div className="icon5">
              <StaticImage src="../../assets/images/hero-icon5.jpg" alt="Isovera One Icons" placeholder="blurred" width={207} quality={100} />
            </div>
            <div className="icon6">
              <StaticImage src="../../assets/images/hero-icon6.jpg" alt="Isovera One Icons" placeholder="blurred" width={178} quality={100} />
            </div>
          </div>
          <h1 className="hero__main-title">{genSettings.title}</h1>
          <h2 className="hero__alt-main-title font-space-mono">Your On-Demand Marketing Team.</h2>
          <h2 className="hero__subtitle">
            <span className="line first">{page.mainTitle.firstLine}</span>
            <span className="line first">{page.mainTitle.secondLine}</span>
            <span className="line first">{page.mainTitle.thirdLine}</span>
          </h2>
          <div className="bottom-text">
            <p className="hero__description font-space-mono">{page.description}</p>
            <Button link="/contact" className="btn btn--primary hero__button" href>
              {page.buttonText}
            </Button>
          </div>
        </div>
      </section>
    );
  }
};

import { useStaticQuery, graphql } from "gatsby";
import React from "react";

import IconChecked from "src/assets/images/ICON-check.inline.svg";
import IconX from "src/assets/images/ICON-x.inline.svg";
import IsoveraSmallIcon from "src/assets/images/isovera-logo-icon.inline.svg";
import { SectionHeading } from "src/components/SectionHeading/SectionHeading";

import "./Plans.scss";

const Plan = ({ value, planList }) => (
  <div className="plan">
    <div className="plan__title">
      <h3>{value.title}</h3>
      <h4>{value.subtitle}</h4>
    </div>
    <div className="plan__plan-options">
      {planList.map((name, index) => {
        return (
          <div key={name} className="plan__plan-option" data-rel={index}>
            <div className="plan-name">{name}</div>
            <div className="plan-checked">{value.planOptions.includes(name) ? <IconChecked /> : <IconX />}</div>
          </div>
        );
      })}
    </div>
  </div>
);

export const Plans = () => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "homepage" }) {
          pageBuilder {
            sections {
              elements {
                ...Element05
              }
            }
          }
        }
        allWp {
          nodes {
            themeSettings {
              acfThemeSettings {
                planOptionsValues
              }
            }
          }
        }
      }
    `
  );

  const element = data.wpPage.pageBuilder.sections[0].elements[4];
  const planListData = data.allWp.nodes[0].themeSettings.acfThemeSettings.planOptionsValues;
  const planList = planListData.split("\r\n");

  return (
    <section className="plans container">
      <SectionHeading title={element.title} subtitle={element.subtitle} className="plans__heading" />

      <div className="plans__polygon">
        <div className="icon">
          <IsoveraSmallIcon />
        </div>
        <div className="text">{element.planDescription.firstLine}</div>
        <div className="text">{element.planDescription.secondLine}</div>
      </div>
      <div className="plans__table-wrap">
        <div className="plans__plans-desktop-list">
          {planList.map((name) => (
            <div key={name} className="plan-name">
              {name}
            </div>
          ))}
          <div className="plan-name dots">. . . </div>
        </div>
        <div className="plans__plans-list">
          {element.plan.map((fields) => (
            <Plan key={fields.title} value={fields} planList={planList} />
          ))}
        </div>
      </div>
      <div className="red-rectangle"></div>
    </section>
  );
};

import classNames from "classnames";
import React, { useState } from "react";

import "./AccordionItem.scss";

export const AccordionItem = (props) => {
  const [setActive, setActiveState] = useState("");
  const openContent = React.createRef();

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "open" : "");
  };

  return (
    <div className={classNames("accordion-item", setActive)}>
      <div className="accordion-item__heading" onClick={() => toggleAccordion()}>
        <h4 className="title">{props.title}</h4>
        <div className={classNames("accordion-item__icon", setActive)}>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
      <div ref={openContent} className={classNames("accordion-item__content", "font-space-mono", setActive)}>
        {props.content}
      </div>
    </div>
  );
};

import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";

import ArrowIcon from "src/assets/images/ICON-biggie-arrow.inline.svg";
import { SlideInElement } from "src/components/SlideInElement/SlideInElement";

import "./CircleLink.scss";

export const CircleLink = ({ to, className, linkTitle }) => {
  return (
    <Link to={to} className={classNames("circle-link", className)}>
      <SlideInElement tag="span" className="circle-link__text" startingOffset="-200">
        {linkTitle}
        <ArrowIcon className="arrow-icon" />
      </SlideInElement>
      <div className="circle-link__circle"></div>
    </Link>
  );
};

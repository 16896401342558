import { useStaticQuery, graphql } from "gatsby";
import React from "react";

import { AccordionItem } from "src/components/AccordionItem/AccordionItem";
import { SectionHeading } from "src/components/SectionHeading/SectionHeading";

import "./Accordion.scss";

export const Accordion = () => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "homepage" }) {
          pageBuilder {
            sections {
              elements {
                ...Element08
              }
            }
          }
        }
      }
    `
  );

  const element = data.wpPage.pageBuilder.sections[0].elements[7];
  return (
    <section className="accordion">
      <SectionHeading title={element.title} className="accordion__heading container" />
      <div className="accordion__container container container--sm">
        {element.question.map((fields, index) => (
          <AccordionItem key={`accordion-${index}`} title={fields.question} content={fields.answer} />
        ))}
      </div>
    </section>
  );
};

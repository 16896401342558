import classNames from "classnames";
import React, { useRef, useState, useEffect } from "react";

import OneIconBack from "src/assets/images/ONE-back.inline.svg";
import OneIcon from "src/assets/images/ONE.inline.svg";

import "./AnimatedOne.scss";

const initialPosition = {
  translateX: 15,
  translateY: -15,
};

export const AnimatedOne = () => {
  const wrapperRef = useRef();

  const [{ translateX, translateY }, setPosition] = useState(initialPosition);
  const [elementWidth, setWidth] = useState(0);
  const [elementHeight, setHeight] = useState(0);

  useEffect(() => {
    setWidth(wrapperRef.current.getBoundingClientRect().width);
    setHeight(wrapperRef.current.getBoundingClientRect().height);
  }, []);

  const handleMouseMove = (e) => {
    setPosition({ translateX: -((e.nativeEvent.offsetX - elementWidth / 2) / 5), translateY: -((e.nativeEvent.offsetY - elementHeight / 2) / 5) });
  };

  const handleMouseLeave = () => {
    setPosition({ ...initialPosition });
  };

  return (
    <div ref={wrapperRef} className={classNames("animated-one")} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      <OneIcon className="animated-one__path animated-one__front" />
      <OneIconBack className="animated-one__path animated-one__back" style={{ transform: `translate3d(${translateX}px, ${translateY}px, 0)` }} />
    </div>
  );
};

//Element03 - Items with icons
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useRef } from "react";

import ArrowLeft from "src/assets/images/arrow-l.inline.svg";
import ArrowRight from "src/assets/images/arrow-r.inline.svg";
import { SectionHeading } from "src/components/SectionHeading/SectionHeading";

import "./ItemsWithIcons.scss";

const TextPath = ({ text, smaller, larger }) => {
  let textLength = text ? text.length : 0;
  let fontsize = "";

  if (smaller) {
    fontsize = ".85rem";
  } else if (larger) {
    if (textLength > 10) {
      fontsize = "1.25rem";
    } else {
      fontsize = "1.5rem";
    }
  }

  return <div style={{ fontSize: `${fontsize}` }}>{text}</div>;
};

const Item = ({ value }) => {
  const extension = value.icon.localFile.extension;
  const icon = extension === "svg" ? "" : getImage(value.icon.localFile);

  return (
    <div className="items-with-icons__item" style={{ backgroundColor: [value.bgcolor] }}>
      <h3 className="items-with-icons__title">
        <TextPath text={value.title.firstLine} larger />
        <TextPath text={value.title.secondLine} smaller />
        <TextPath text={value.title.thirdLine} smaller />
      </h3>
      <div className="items-with-icons__icon">
        {extension === "svg" ? (
          <img src={value.icon.localFile.publicURL} className="icon-image" />
        ) : (
          <GatsbyImage image={icon} alt={value.title.firstLine} className="icon-image" />
        )}
      </div>
    </div>
  );
};

export const ItemsWithIcons = () => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "homepage" }) {
          pageBuilder {
            sections {
              elements {
                ...Element03
              }
            }
          }
        }
      }
    `
  );

  const element = data.wpPage.pageBuilder.sections[0].elements[2];

  const scrollRef = useRef();

  const scrollHandler = (direction) => {
    let scrollAmount, n, counter;
    scrollAmount = n = counter = 0;
    let easingCounter = Array(50);

    for (let i = 0; i < 50; i++) {
      easingCounter[i] = n;
      if (i < 25) {
        n = n + 0.4;
      } else {
        n = n - 0.4;
      }
    }

    const slideTimer = setInterval(function () {
      if (direction === "left") {
        scrollRef.current.scrollLeft -= easingCounter[counter] - 0.8;
      } else {
        scrollRef.current.scrollLeft += easingCounter[counter];
      }
      scrollAmount += 5;
      counter++;
      if (scrollAmount >= 250) {
        window.clearInterval(slideTimer);
      }
    }, 5);
  };

  return (
    <section className="items-with-icons">
      <div className="container items-with-icons__container">
        <SectionHeading title={element.title} className="items-with-icons__heading" />
        <div ref={scrollRef} className="items-with-icons__items" id="js-icon-items">
          {element.item.map((fields, index) => (
            <Item key={"item-icon-" + index} value={fields} />
          ))}
        </div>
        <div className="items-with-icons__arrows">
          <button className="items-with-icons__arrow left c-white-button" onClick={() => scrollHandler("left")}>
            <ArrowLeft />
          </button>
          <button className="items-with-icons__arrow right c-secondary-button" onClick={() => scrollHandler("right")}>
            <ArrowRight />
          </button>
        </div>
      </div>
    </section>
  );
};

import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export const SlideInElement = ({
  tag: CustomTag = "div",
  children,
  className,
  axis = "translateX",
  startingOffset = "300",
  opacityDuration = "700",
  duration = "700",
  delay = 0,
}) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  // return () => clearTimeout(timer);

  useEffect(() => {
    if (inView) {
      ref.current?.play();
    } else {
      ref.current?.pause();
    }
  }, [ref, inView]);

  return (
    <CustomTag
      style={{
        transition: `opacity ${opacityDuration}ms, transform ${duration}ms`,
        opacity: inView ? 1 : 0,
        transform: `${axis}(${inView ? 0 : startingOffset}px)`,
        transitionDelay: delay,
      }}
      ref={ref}
      className={className}
    >
      {children}
    </CustomTag>
  );
};

import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import * as React from "react";

import { Layout } from "../components/Layout";
import { Accordion } from "../elements/Accordion/Accordion";
import { GetStarted } from "../elements/GetStarted/GetStarted";
import { Hero } from "../elements/Hero/Hero";
import { ItemsWithIcons } from "../elements/ItemsWithIcons/ItemsWithIcons";
import { Plans } from "../elements/Plans/Plans";
import { TextWithImage } from "../elements/TextWithImage/TextWithImage";
import { TitleGraph } from "../elements/TitleGraph/TitleGraph";
import { Work } from "../elements/Work/Work";

const HomePage = ({ data: { wpPage } }) => {
  return (
    <Layout>
      <Seo post={wpPage} />
      <Hero />
      <TextWithImage />
      <ItemsWithIcons />
      <TitleGraph />
      <Plans />
      <GetStarted elementPosition={5} />
      <Work />
      <Accordion />
      <GetStarted elementPosition={8} />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    wpPage(slug: { eq: "homepage" }) {
      nodeType
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`;

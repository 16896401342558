import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import { SectionHeading } from "src/components/SectionHeading/SectionHeading";

import "./Work.scss";

export const Work = () => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "homepage" }) {
          pageBuilder {
            sections {
              elements {
                ...Element07
              }
            }
          }
        }
      }
    `
  );

  const element = data.wpPage.pageBuilder.sections[0].elements[6];

  const WorkItem = ({ value }) => {
    const image = getImage(value.image.localFile);

    return (
      <div className={classNames("work__item", value.alignImage)}>
        <div className="work__description">
          <div className="wrap">
            <h3>{value.title}</h3>
            <p className="font-space-mono">{value.description}</p>
          </div>
        </div>
        <div className="work__image">
          <GatsbyImage className="" image={image} alt={value.title} />
        </div>
      </div>
    );
  };

  return (
    <section className="work">
      <SectionHeading title={element.title} className="work__heading container" />
      <div className="">
        {element.items.map((fields, index) => (
          <WorkItem key={"work-items-" + index} value={fields} />
        ))}
      </div>
    </section>
  );
};

import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import IconArrowSmall from "src/assets/images/Arrowthick.inline.svg";
import IconResponsive from "src/assets/images/ICON-responsive.inline.svg";
import { SlideInElement } from "src/components/SlideInElement/SlideInElement";

import "./TextWithImage.scss";

export const TextWithImage = () => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "homepage" }) {
          pageBuilder {
            sections {
              elements {
                ...Element02
              }
            }
          }
        }
        placeholderImage: file(relativePath: { eq: "ICON_Splash.svg" }) {
          extension
          publicURL
        }
      }
    `
  );

  const element = data.wpPage.pageBuilder.sections[0].elements[1];

  return (
    <section className="text-with-image container-grid">
      <div className="text-with-image__content">
        <SlideInElement className="text-with-image__arrow" startingOffset="-200" duration="300" delay="1000ms">
          <IconArrowSmall />
        </SlideInElement>
        <div className="title">
          <SlideInElement tag="h2" startingOffset="-200" duration="700">
            {element.title}
          </SlideInElement>
          <SlideInElement tag="h3" startingOffset="-200" duration="700" delay="300ms">
            {element.subtitle}
          </SlideInElement>
        </div>
        <SlideInElement className="text-with-image__arrow" startingOffset="-200" duration="300" delay="1300ms">
          <IconArrowSmall />
        </SlideInElement>
        <SlideInElement tag="p" className="font-space-mono" startingOffset="-200" duration="300" delay="500ms">
          {element.description}
        </SlideInElement>
      </div>
      <div className="text-with-image__image">
        <SlideInElement startingOffset="200" duration="700" delay="850ms">
          <StaticImage src="../../assets/images/digital-success-plan.png" alt="Isovera One" placeholder="traced_svg" width={553} />
        </SlideInElement>
        <SlideInElement startingOffset="200" duration="700" delay="1250ms">
          <IconResponsive />
        </SlideInElement>
      </div>
    </section>
  );
};

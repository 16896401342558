import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";

import { AnimatedOne } from "src/components/AnimatedOne/AnimatedOne";
import { CircleLink } from "src/components/CircleLink/CircleLink";
import { SlideInElement } from "src/components/SlideInElement/SlideInElement";

import "./GetStarted.scss";

export const GetStarted = ({ elementPosition }) => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "homepage" }) {
          pageBuilder {
            sections {
              elements {
                ...Element06
              }
            }
          }
        }
      }
    `
  );

  const element = data.wpPage.pageBuilder.sections[0].elements[elementPosition];

  const spanString = (string, part) => {
    let html = "<span>" + part + "</span>";
    let replacedString = string.replace(part, html);
    return { __html: replacedString };
  };

  let description = spanString(element.description, element.highlightText);

  return (
    <section className={classNames("get-started", element.grayBackground ? "gray-background" : "")}>
      <div className="get-started__content container">
        <h3 className="get-started__description" dangerouslySetInnerHTML={description}></h3>
        <CircleLink to="/contact" className="get-started__link" linkTitle={element.linkTitle}></CircleLink>
      </div>
      {element.showImage ? (
        <SlideInElement className="get-started__img">
          <AnimatedOne />
        </SlideInElement>
      ) : (
        false
      )}
    </section>
  );
};
